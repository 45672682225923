.error-page {
    padding: 40px 0 120px;
    background-color: #fff;
    border-radius: 10px;
    background-image: url("/assets/renewal/base/img/error/box-deco.svg");
    background-position: center bottom;
    background-size: 100% auto;
    background-repeat: no-repeat
}

.error-page.request-page {
    background-image: none;
    padding: 220px 0 220px
}

.error-page.request-page .error-page__catch--img {
    width: 96px;
    height: 76px
}

.error-page.request-page .error-page__catch h1 {
    margin-top: 15px
}

.error-page__catch {
    text-align: center
}

.error-page__catch--img {
    width: 70px;
    height: 69px;
    margin-inline:auto}

.error-page__catch h1 {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: .04em;
    margin-top: 20px
}

.error-page__catch p {
	font-family: "Roboto","Noto Sans JP",sans-serif;
    padding: 25px 10px 0px 10px;
    display: block;
    text-align: left;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 2.0;
}

.error-page__catch h3 {
	font-size: 16px;
    font-weight: 700;
    letter-spacing: .04em;
    margin-top: 15px;
}


.error-page__btnlist {
    display: flex;
    justify-content: center;
    gap: 20px
}

.error-page__btn {
    padding: 10px 20px;
    background-color: #6eb92b;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 6px
}

.error-page__btn_big {
    padding: 20px 40px;
    background-color: #6eb92b;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 6px
}


.error-page__btn svg {
    padding-top: 1px
}

.error-page__btn:hover {
    background-color: #538b21
}

.error-page__btn.error-page__btn-gray {
    background-color: #616161
}

.error-page__btn.error-page__btn-gray:hover {
    background-color: #868686
}

